import Navbar from "../Components/Navbar/Navbar";
import { FaDiscord, FaInstagram } from "react-icons/fa";
import React from "react";
import {
  FiCode,
  FiGithub,
  FiLinkedin,
  FiMessageSquare,
  FiUser,
  FiMail,
} from "react-icons/fi";
import { FaSchool } from "react-icons/fa";

function About() {
  return (
    <div className="w-full lg:h-screen sm:h-full items-center justify-center">
      <Navbar />
      <div className="pt-[62px] w-full h-full z-6 " style={{ top: 62 }}>
        <div
          className="flex  flex-row justify-center items-center min-h-full bg-app-background bg-center bg-no-repeat bg-cover pt-[62px] w-full h-full z-6 "
          style={{ top: 62 }}
        >
          <main
            className="flex  items-center justify-center flex-col lg:flex-row bg-white bg-opacity-10 rounded-3xl overflow-hidden w-full max-w-7xl shadow-lg m-4 lg:m-4"
            style={{
              backdropFilter: "blur(10px)",
            }}
          >
            <div
              className="py-8 px-4 lg:px-6 bg-white bg-opacity-10 flex flex-col w-full lg:w-auto lg:max-w-xs"
              style={{
                backdropFilter: "blur(10px)",
              }}
            >
              <img
                className="rounded-full shadow-sm  mb-4 border-2 max-h-48 border-white select-none mx-auto"
                // src={"https://i.ibb.co/7gpwLXm/tsa-2.jpg"}
                src={"https://tanishq.technyteams.co.in/images/TSA_JOD.png"}
                alt={"Tanishq"}
                draggable={false}
              />

              <div className="text-white text-lg font-black flex items-center mb-2">
                <FiUser />
                <span className="ml-4">Tanishq Singh Anand</span>
              </div>

              <div className="text-white flex items-center mb-2">
                <FaSchool />
                <span className="ml-4">Sri Chaitanya, Class XI</span>
              </div>

              <div className="text-white flex items-center">
                <FiMessageSquare />
                <span className="ml-4">
                  I'm a proficient developer experienced in JavaScript, Python,
                  and React.
                </span>
              </div>

              <div className="flex flex-row flex-wrap justify-center mt-auto">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/TanishqSinghAnand"
                  className="rounded-full w-10 h-10 flex flex-row justify-center items-center bg-white bg-opacity-10 hover:bg-opacity-20 text-white mt-4 mx-1"
                >
                  <FiGithub />
                </a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/tanishq-s-anand-91743119b/"
                  className="rounded-full w-10 h-10 flex flex-row justify-center items-center bg-white bg-opacity-10 hover:bg-opacity-20 text-white mt-4 mx-1"
                >
                  <FiLinkedin />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://discord.com/users/1096877950164553868"
                  className="rounded-full w-10 h-10 flex flex-row justify-center items-center bg-white bg-opacity-10 hover:bg-opacity-20 text-white mt-4 mx-1"
                >
                  <FaDiscord />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/tanishq0504/"
                  className="rounded-full w-10 h-10 flex flex-row justify-center items-center bg-white bg-opacity-10 hover:bg-opacity-20 text-white mt-4 mx-1"
                >
                  <FaInstagram />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:anandtanishqs@gmail.com"
                  className="rounded-full w-10 h-10 flex flex-row justify-center items-center bg-white bg-opacity-10 hover:bg-opacity-20 text-white mt-4 mx-1"
                >
                  <FiMail />
                </a>
              </div>
            </div>

            <div className="flex-1 p-4 lg:p-6">
              <div className="text-lg text-white font-black mb-8 flex items-center">
                <FiCode />
                <div className="ml-4">About</div>
              </div>

              <div className="w-full text-white text-lg">
                <p className="w-full">
                  I'm an India-based React Native developer who thrives on the
                  art of coding. With a strong proficiency in JavaScript and
                  Python, I have honed my skills to deliver outstanding web
                  technologies. My passion lies in creating innovative products,
                  with a particular interest in the realms of Deep Learning and
                  Natural Language Processing.
                  <br />
                  <br />
                  In addition to my expertise in React Native, I am well-versed
                  in Node.js and modern JavaScript libraries such as React.js
                  and Next.js. This allows me to develop high-quality
                  applications that seamlessly integrate with today's fast-paced
                  digital landscape. I constantly seek opportunities to push
                  boundaries and stay ahead of industry trends.
                  <br />
                  <br />
                  Driven by a love for coding, I enjoy working during the night
                  to explore new possibilities and deliver exceptional results.
                  Whether it's building cutting-edge web solutions or diving
                  into complex algorithms, I am committed to crafting impactful
                  and user-centric experiences. Let's collaborate and transform
                  your ideas into reality with the power of technology.
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default About;
