const blogs = [
  {
    title: "Chapter 3 ",
    thumbnail:
      "https://e7.pngegg.com/pngimages/440/183/png-clipart-computer-icons-input-devices-input-output-icon-design-input-angle-text.png",
    description: "Take input in the BlueJ terminal ",
    gitLink: "https://github.com/Teachny-Teams/RadiusInputInTerminal",
    gistLink:
      "https://gist.github.com/TanishqSinghAnand/c6a00d623b787d154eab4e3c6d0d82a3",
  },
];

export default blogs;
